export default {

  'slot1': "4771390347",
  'slot2': "6405688601",
  'slot3': "5892900321",
  'firebaseConfig': {
 apiKey: "AIzaSyDEyOwo6oFY7Pt83OA4LbcH2mPqutJhl98",
  authDomain: "noticesite-b0d1f.firebaseapp.com",
  projectId: "noticesite-b0d1f",
  storageBucket: "noticesite-b0d1f.appspot.com",
  messagingSenderId: "854384302755",
  appId: "1:854384302755:web:24d2c2bca398f71e35bd16",
  measurementId: "G-240MSZJM30"
  }
}