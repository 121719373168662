import Vue from 'vue'
import App from './App.vue'
import router from '@/router/news'
import global from '@/utlis/global.js'
import store from '@/store'
import Meta from 'vue-meta'
import '@/utlis/remcommon.js'
import VueLazyload from 'vue-lazyload'
import { initializeApp} from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
Vue.config.productionTip = false
Vue.prototype.$global = global
const loadimage = require('@/assets/news/loading.png')
Vue.prototype.$api = global.api
import '../../utlis/vant'
import document from '@/utlis/document.js'
Vue.use(Meta)
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: loadimage,
	loading: loadimage,
	attempt: 1,
})
const firebaseConfig =  {
  apiKey: "AIzaSyA_deiO3J03dr2s-iuvGUCv6HMHFqduIPc",
  authDomain: "news-h5.firebaseapp.com",
  projectId: "news-h5",
  storageBucket: "news-h5.appspot.com",
  messagingSenderId: "543685424336",
  appId: "1:543685424336:web:18ce043e12a4e63f9b9ad5",
  measurementId: "G-Z5DRRRNVT6"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
Vue.prototype.$logEvent = (event, params) => {
  logEvent(analytics, event, params);
};
Vue.prototype.$eventrackFb = (msg, method,map = {}) => {
  let params = {
    time: new Date(),
    message: msg,
     method: method,
    ...map,
  };
  logEvent(analytics, msg, params);
};
new Vue({                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
  router,store,
  render: h => h(App),
  data() {
    return {
      firebaseConfig: {}
    }
   },
  created() { 
    this.getInfo()
  },
  methods: {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
    getInfo() {
      const href = window.location.href
      let domain = new URL(href).hostname
      if (domain === 'localhost') {
        domain = "www.shop13.fyi"
      }
      if (domain.substr(0, 3) !== 'www') {
        domain = 'www.' + domain
        this.$store.commit("UPDATE_ROOTDOMAIN", domain);
      } else { 
        let rootDomain = domain.substr(4, domain.length)
         this.$store.commit("UPDATE_ROOTDOMAIN", rootDomain);
      }
      global.api.getNewsInfo({ domain }).then(res => {
      if (res.result_code == '200') { 
      const { slot1, slot2, slot3, data_ad_client } = res.data
      this.$store.commit("UPDATE_SLOT1", slot1);
      this.$store.commit("UPDATE_SLOT2", slot2);
      this.$store.commit("UPDATE_SLOT3", slot3);
      this.$store.commit("UPDATE_DATA_AD_CLIENT", data_ad_client);
        }
      })
    
    },
    initializeFirebase() { 
      const app = initializeApp(this.firebaseConfig);
      const analytics = getAnalytics(app);
      Vue.prototype.$logEvent = (event, params) => {
        logEvent(analytics, event, params);
      };
      Vue.prototype.$eventrackFb = (msg, method, map = {}) => {
        let params = {
          time: new Date(),
          message: msg,
          method: method,
          ...map,
        };
        logEvent(analytics, msg, params);
      };
    }
  }


}).$mount('#app')
